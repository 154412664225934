import { defineMessages } from "react-intl";
export default defineMessages({
  start: {
    id: "a2vote.viewsession.start",
    defaultMessage: "Start the session",
  },
  end: {
    id: "a2vote.viewsession.end",
    defaultMessage: "End the session",
  },
  closed: {
    id: "a2vote.viewsession.closed",
    defaultMessage: "Session closed"
  },
  return_list: {
    id: "a2vote.viewsession.return.list",
    defaultMessage: "Back to the list of sessions",
  },
  add: {
    id: "a2vote.addvote.add.deliberation",
    defaultMessage: "Add a deliberation",
  },
  list_proxies: {
    id: "a2vote.viewsession.list.proxies",
    defaultMessage: "List of participants and proxies",
  },
  vote_upload_loading: {
    id: "a2vote.addvote.upload.loading",
    defaultMessage: "Adding the new vote in progress...",
  },
});

import { defineMessages } from "react-intl";
export default defineMessages({
  create_session: {
    id: "a2vote.session.create",
    defaultMessage: "+ Add Session",
  },
  new_deliberation_session: {
    id: "a2vote.session.new_deliberation_session",
    defaultMessage: "New deliberation session", 
  },
  session_name: {
    id: "a2vote.session.session_name",
    defaultMessage: "Session name",
  },
  enter_session_name: {
    id: "a2vote.session.enter_session_name",
    defaultMessage: "Enter session name",
  },
  session_object: {
    id: "a2vote.session.session_object",
    defaultMessage: "Session object",
  },
  enter_session_object: {
    id: "a2vote.session.enter_session_object",
    defaultMessage: "Enter session object",
  },
  session_date: {
    id: "a2vote.session.session_date",
    defaultMessage: "Session date",
  },
  session_hour: {
    id: "a2vote.session.session_hour",
    defaultMessage: "Session hour",
  },
  session_place: {
    id: "a2vote.session.session_place",
    defaultMessage: "Session place",
  },
  enter_session_place: {
    id: "a2vote.session.enter_session_place",
    defaultMessage: "Enter session place",
  },
  enter_session_lien:{
    id: "a2vote.session.enter_session_lien",
    defaultMessage: "Enter session link",
  },
  user_group_summon: {
    id: "a2vote.session.user_group_summon",
    defaultMessage: "User group summon",
  },
  add_agenda: {
    id: "a2vote.session.add_agenda",
    defaultMessage: "Add agenda",
  },
  import_agenda: {
    id: "a2vote.session.import_agenda",
    defaultMessage: "Import agenda"
  },
  user_group: {
    id: "a2vote.session.user_group",
    defaultMessage: "User group",
  },
  no_file_upload: {
    id: "a2vote.session.no_file_upload",
    defaultMessage: "No file upload",
  },
  file_error: {
    id: "a2vote.session.file_error",
    defaultMessage: "Oops, an error has occured with this file.",
  },
  consult_session: {
    id: "a2vote.session.consult_session",
    defaultMessage: "Consult session",
  },
  vote_count: {
    id: "a2vote.session.vote_count",
    defaultMessage: "Vote count",
  },
  session_location: {
    id: "a2vote.session.session_location",
    defaultMessage: "Session take place at :",
  },
  session_location2: {
    id: "a2vote.session.session_location2",
    defaultMessage: "Web link",
  },
  none: {
    id: "a2vote.session.none",
    defaultMessage: "none"
  },
  edit_deliberation_session: {
    id: "a2vote.session.edit_deliberation_session",
    defaultMessage: "Edit deliberation session",
  },
  session_edit: {
    id: "a2vote.session.session_edit",
    defaultMessage: "Edit session",
  },  
  session_add: {
    id: "a2vote.session.session_add",
    defaultMessage: "Add session",
  },
  session_filter_title: {
    id: "a2vote.title",
    defaultMessage: "Title",
  },
  session_filter_description: {
    id: "a2vote.description",
    defaultMessage: "Description",
  },
  session_filter_location: {
    id: "a2vote.filter.location",
    defaultMessage: "Location",
  },
  session_filter_date_start: {
    id: "a2vote.filter.date.start",
    defaultMessage: "From"
  },
  session_filter_date_end: {
    id: "a2vote.filter.date.end",
    defaultMessage: "At"
  },
  session_upload_loading: {
    id: "a2vote.session.upload.loading",
    defaultMessage: "Adding the new session in progress...",
  },
  view_results: {
    id: "a2vote.view.results",
    defaultMessage: "See results",
  },
  delete_filters: {
    id: "a2vote.delete.filters",
    defaultMessage: "Delete filters",
  },
  filters: {
    id: "a2vote.filters",
    defaultMessage: "Filters"
  },
  session_title_error:{
    id: "a2vote.title_error",
    defaultMessage: "Title required"
  },
  session_description_error:{
    id: "a2vote.description_error",
    defaultMessage: "Must not exceed 255 characters"
  },
  session_link_error:{
    id: "a2vote.link.error",
    defaultMessage: "Link not valid"
  },

});

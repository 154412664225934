import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Tools
import { A2VOTE_API } from "utils/config";
import { getPdfSrc } from "utils/pdf";
import styled from "styled-components";
import Icon from "utils/icon";
import theme from "theme";
// i18n
import { FormattedMessage, useIntl } from "react-intl";
import globalMessages from "containers/messages"
import messages from "./messages";
// Containers
import AddVote from "./AddVote";
import ViewVotes from "./ViewVotes";
// Components
import Button from "components/Button/Button";
// Actions
import {
  selectSessionAction,
  showAddVoteAction,
  startSession as startSessionAction,
  endSession as endSessionAction,
  getSessionVotes as getSessionVotesAction,
} from "actions/session";
import { navigate } from "gatsby";
import { Lazyload } from "../../../components/lazyload/lazyload";

const ViewSessionContainer = styled.div`
  height: calc(100% - 56px);
  width: 100%;
  ${props => props.upload && `pointer-events: none;` || null}
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;

  @media(max-width: 768px) {
    display: flex;
    flex-flow: column;
    padding: 0 16px;
    width: calc(100% - 32px);
  }
`;

const ButtonListProxies = styled.div`
  display: flex;
  background-color: ${theme.ui.blue};
  align-items: center;
  border-radius: 5px;
  padding-left: 8px;
  cursor: pointer;

  @media(max-width: 768px) {
    justify-content: center;
  }
`;

const EndSession = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  @media(max-width: 768px) {
    border-top: 1px solid ${theme.ui.grey};
    padding: 16px;
    width: 100%;
  }
`;

const Return = styled.div`
  padding: 16px 32px;
  width: 80px;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  color: ${theme.darkgray};
  gap: 8px;

  @media(max-width: 768px) {
    padding: 16px;
  }
`;

const SetColumn = styled.div`
  display: flex;
  height: calc(100% - 56px);
  & > div {
    width: 50%;
    overflow: auto;
  }

  @media(max-width: 768px) {
    & > div {
      width: 100%;
    }
  }
`;

const LeftSide = styled.div`
  height: calc(100% - 61px);

  @media(max-width: 768px) {
    height: calc(100% - 170px);
  }
`;

const ColumnButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 16px;
  border-bottom: 1px solid ${theme.ui.grey};

  @media(max-width: 1245px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    padding: 0 32px 16px;
    gap: 8px;

    & > button {
      margin-bottom: 8px;
    }
  }

  @media(max-width: 768px) {
    padding: 0;
    display: flex;
    flex-flow: column;
    gap: 16px;
  }
`;

const AddSection = styled.div`
  display: flex;
  align-items: center;
`;

const Loading = styled.div`
margin-left: 10px;
width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,${theme.ui.blue} 95%,#0000) 50% 1px/8px 8px no-repeat,
    radial-gradient(farthest-side,#0000 calc(100% - 9px),#ccc 0);
  animation: s9 2s infinite linear;
}
@keyframes s9 {to{transform: rotate(1turn)}}
`;


const SlideBarBackground = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
  background-color: ${props => props.isOpen ? "rgba(9,67,103,.3)" : ""};
  z-index: ${props => props.isOpen ? "2" : "-1"};
`;

const FileColumn = styled.div`
  background: ${theme.gray};

  @media(max-width: 768px) {
    display: none;
  }
`;

const ViewSession = () => {
  // bind state
  const { selectedSession, showAddVote, votes, getAllVotes } = useSelector(
    (state) => state.session
  );
  const { upload } = useSelector((state) => state.voteReducer);
  // bind actions
  const dispatch = useDispatch();
  const selectSession = (session) => dispatch(selectSessionAction(session));
  const addVote = () => dispatch(showAddVoteAction());
  const getSessionVotes = () => dispatch(getSessionVotesAction(selectedSession.id, null));
  const startSession = () => dispatch(startSessionAction(selectedSession));
  const endSession = () => dispatch(endSessionAction(selectedSession));
  // state
  const [previewHeight, setPreviewHeight] = useState(0);

  const [numberPage, setNumberPage] = useState(0);

  const intl = useIntl();

  // ref
  const setColumnRef = useRef(null);

  useEffect(() => {
    if(getAllVotes){
      getSessionVotes();
    }
  }, [getAllVotes])

  useEffect(() => {
    if(setColumnRef?.current?.offsetHeight) {
      setPreviewHeight(setColumnRef.current.offsetHeight);
    }
  }, [setColumnRef])
   useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${A2VOTE_API}/file/m/${selectedSession.filename}`);
      const json = await data.json();
      setNumberPage(json)
    }
    fetchData()
      .catch(console.error);
    getSessionVotes();
  }, [])

  const displayFile = () =>  {
    const filename = selectedSession?.filename;
    if (filename) {
      if (filename.toLowerCase().includes(".pdf")) {
        let pdfSrc = getFileSource();
        pdfSrc = getPdfSrc(pdfSrc);
        return (
          <iframe title="pdf-viewer" src={pdfSrc + "#toolbar=0"} width="100%" height={`${previewHeight-60}px`} frameBorder="0"/>
        );
      } else {
        if(numberPage == 1){
          return <Lazyload src={getFileSource()} />
        }
        else {
             const pages = [];
             for(let j=1;j<=numberPage;j++) {
               pages.push(<Lazyload key={j} src={getPagesFileSource(j)} />);
             }

           return (<div>{ pages }</div>);
        }
      }
    }
    return null;
  };

  const getPagesFileSource = (index) => {
    let filenameWithoutExtension = selectedSession.filename?.replace('.jpg','');
    return `${A2VOTE_API}/file/a/${filenameWithoutExtension}/${index}`;
  };
  const getFileSource = () => {
    return `${A2VOTE_API}/file/o/${selectedSession.filename}`;
  };

  const getStatusButton = () => {
    switch(selectedSession?.status) {
      case 0:
        return (
          <Button onClick={() => startSession()}>
            <FormattedMessage {...messages.start} />
          </Button>
        )
      case 1:
        return (
          <Button backgroundColor="red" onClick={() => endSession()}>
            <FormattedMessage {...messages.end} />
          </Button>
        )
      case 2:
        return (
          <Button disable>
            <FormattedMessage {...messages.closed} />
          </Button>
        )
      default:
        return null;
    }
  }

  useEffect(() => {
    if (upload) {
      dispatch({
        type: "CREATE_AND_UPDATE_ALERT",
        payload: {
          content: (
            <AddSection>
              {upload.toString().substring(0, 3)}
              {`% - `}
              {intl.formatMessage(messages.vote_upload_loading)}
            </AddSection>
          ),
          options: {
            position: "top-center",
            id: "upload",
            icon: <Loading />,
          },
          type: "loading",
        },
      });
    } else {
      dispatch({
        type: "REMOVE_ALERT",
        payload: {
          id: "upload",
        },
      });
    }
  }, [upload]);


  return (
    <ViewSessionContainer upload={upload}>
      <Return onClick={() => selectSession(null)}>
        <Icon icon="LeftArrow" size="sm"/>
        <FormattedMessage {...globalMessages.return} />
      </Return>
      <ColumnButtons>
        <Buttons>
          <Button onClick={() => addVote()}>
            <FormattedMessage {...messages.add} />
          </Button>
          <ButtonListProxies onClick={() => navigate("/s/p")}>
            <Icon icon="Users" size="sm"/>
            <Button>
              <FormattedMessage {...messages.list_proxies} />
            </Button>
          </ButtonListProxies>
        </Buttons>
        <EndSession>
          {getStatusButton()}
        </EndSession>
      </ColumnButtons>
      <SetColumn ref={setColumnRef}>
        <LeftSide>
          <SlideBarBackground isOpen={showAddVote}/>
          {showAddVote && <AddVote />}
          <ViewVotes />
        </LeftSide>
        <FileColumn>{displayFile()}</FileColumn>
      </SetColumn>
    </ViewSessionContainer>
  );
};

export default ViewSession;
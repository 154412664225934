import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// tools
import styled from 'styled-components';
// actions
import {
  startVote as startVoteAction,
  endVote as endVoteAction,
  showAddVoteAction,
  deleteSessionVote as deleteVoteAction
} from 'actions/session';
import {
  selectVoteResults as selectVoteResultsAction
} from 'actions/voteResults';
// utils
import Icon from "utils/icon";
// style
import theme from 'theme';
// components
import Button from "components/Button/Button";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { ButtonWithMargin } from 'components/Button/Button';

const VoteLine = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${theme.ui.grey};
  color: ${theme.darkgray};
  background: ${theme.aliceblue};
  display: flex;
  flex-flow: column;
  gap: 8px;

  @media(max-width: 768px) {
    padding: 16px;
  }
`;

const Infos = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
`;

const Title = styled.div`
  color: ${theme.darkgray};
  font-size: 16px;
`;

const Description = styled.div`
  color: ${theme.mediumgray};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DisplayButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media(max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
  }
`

const ResultButton = styled.div`
`

const EditButton = styled.div`
  background-color: ${theme.ui.blue};
  border-radius: 5px;
  padding: 1px;
  height: fit-content;
`;

const DeleteButton = styled(EditButton)`
  background-color: ${theme.red};
`;

const MyLocalButton = styled(ButtonWithMargin)`
`;

const LoadMessage = styled.div`
  padding: 16px 32px;
  display: flex;
  align-items: center;
  gap: 8px;

  @media(max-width: 768px) {
    padding: 16px;
  }
`;

const RightSide = styled.div``;
const LeftSide= styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ViewVotes = () => {
  // bind state
  const { selectedSession, votes, fetchingGetVotes } = useSelector((state) => state.session);
  // bind actions
  const dispatch = useDispatch();
  const startVote = (session, vote) => dispatch(startVoteAction(session, vote));
  const endVote = (session, vote) => dispatch(endVoteAction(session, vote));
  const deleteVote = (session, vote) => dispatch(deleteVoteAction(session, vote));
  const selectVoteResults = (vote) => dispatch(selectVoteResultsAction(vote));
  const addVote = (vote) => dispatch(showAddVoteAction(vote));


  const displayStatus = (vote) => {
    switch(vote.status) {
      case 0:
        return <MyLocalButton backgroundColor={"green"} onClick={()=>startVote(selectedSession, vote)} ><FormattedMessage {...messages.launch} /></MyLocalButton>       
      case 1:
        return <MyLocalButton backgroundColor={"red"} onClick={()=>endVote(selectedSession, vote)} ><FormattedMessage {...messages.finish} /></MyLocalButton>
      default:
        return null;
    }
  }

  if(fetchingGetVotes) {
    return (
      <LoadMessage>
        <Icon icon="Load" />
        <FormattedMessage {...messages.load} />
      </LoadMessage>
    )
  }

  return (
    <div>
      {
        votes.map((vote) => {
          return (
            <VoteLine key={vote.id}>
              <Infos>
                <Title>{vote.title}</Title>
                <Description>{vote.description}</Description>
              </Infos>
              <DisplayButton>
                <LeftSide>
                  <ResultButton>
                    <Button onClick={()=>selectVoteResults(vote)}>
                      <FormattedMessage {...messages.result} />
                    </Button>
                  </ResultButton>
                  <EditButton>
                    <Button onClick={() => addVote(vote)}>
                      <Icon icon="EditPen" size="sm"/>
                    </Button>
                  </EditButton>
                  <DeleteButton>
                    <Button backgroundColor={"red"} onClick={() => deleteVote(selectedSession, vote)} >
                      <Icon icon="Bin2" size="sm"/>
                    </Button>
                  </DeleteButton>
                </LeftSide>
                <RightSide>
                  {selectedSession?.status === 1 && displayStatus(vote)}
                </RightSide>
              </DisplayButton>
            </VoteLine>
          )
        })
      }
    </div>
  )
}

export default ViewVotes;
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// tools
import styled from 'styled-components';
// actions
import { exitVoteResults as exitVoteResultsAction, exportSessionResult as exportSessionResultAction } from 'actions/voteResults';
import { getSessionVotes as getSessionVotesAction } from 'actions/session';
// components
import VotesViewer from 'components/VotesViewer';
import UserVotes from 'components/UserVotes';
import Button from 'components/Button/Button';
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import globalMessages from "containers/messages"

// style
import theme from 'theme';
// utils
import Icon from "utils/icon";

const HEADER_HEIGHT = "60px";
const TOPSECTION_HEIGHT = "115px";

const VRContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const VRHeader = styled.div`
  background: ${theme.ui.blue};
  color: white;
  width: 100%;
  height: ${HEADER_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;

const VRBody = styled.div`
  width: 100%;
  height: calc(100% - ${TOPSECTION_HEIGHT});
  display: flex;
`;

const VRResults = styled.div`
  width: 30%;

  @media(max-width: 900px) {
    display: none;
  }
`;

const VRMain = styled.div`
  width: 70%;
  height: 100%;

  @media(max-width: 900px) {
    width: 100%;
    height: calc(100% - 16px);
    overflow-y: scroll;
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 32px;

  @media(max-width: 900px) {
    margin: 16px;
  }
`;

const ReturnDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: ${theme.darkgray};
`;

const Results = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  gap: 8px;
  overflow-y: auto;
  margin: 0 32px 16px 32px;

  @media(max-width: 900px) {
    overflow-y: auto;
    margin: 0 16px 16px 16px;
  }
`;

const VoteResults = () => {
  // bind global state
  const { selectedSession  } = useSelector((state) => state.session);
  const { vote }  = useSelector((state) => state.voteResults);
  //
  const dispatch = useDispatch();
  const exitVoteResults = () => dispatch(exitVoteResultsAction());
  const getSessionVotes = () => dispatch(getSessionVotesAction(selectedSession.id, vote));
  const exportSessionVotes = () => dispatch(exportSessionResultAction(vote.id, vote.title));

// selectionSession.id
// vote.id
  return (
    <VRContainer>
      <VRHeader>
        {selectedSession?.title} - {vote?.title}
      </VRHeader>
      <VRBody>
        <VRResults>
          <VotesViewer results={vote?.results}/>
        </VRResults>
        <VRMain>
          <TopSection>
            <ReturnDiv onClick={exitVoteResults}>
              <Icon icon="LeftArrow" size="sm"/>
              <FormattedMessage {...globalMessages.return} />
            </ReturnDiv>
            <Button onClick={exportSessionVotes}>
              <FormattedMessage  {...messages.export} />
            </Button>
            <Button onClick={getSessionVotes}> <FormattedMessage {...messages.refresh} /></Button>
          </TopSection>
          <Results>
            <UserVotes userLists={selectedSession?.userLists} proxies={selectedSession.proxies} results={vote?.results}/>
          </Results>
        </VRMain>
      </VRBody>
    </VRContainer>
  )
}

export default VoteResults
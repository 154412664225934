import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
// Tools
import Select from "react-select";
import { A2VOTE_API } from "utils/config";
import styled from "styled-components";
import theme from "../../../theme";
// Actions
import { addSessionAction, hideAddSessionAction, editSessionAction } from 'actions/session';
// Components
import SidePageLayout, {Input, TextArea, TimeAndDate, DoubleInputContainer, Label, IconStyled, FileName, FileContainer, customStyles} from "../../Layout/SidePage";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "../messages";
import { Lazyload } from "../../../components/lazyload/lazyload";

const MessageError = styled.div`
  color: ${theme.red};
  margin: 0 0 6px;
`;

const AddSession = () => {
  // state
  const [day, setDay] = useState(new Date().toISOString().split('T')[0]);
  const [time, setTime] = useState(new Date().getHours() + ':' + (new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()));
  const [formData, setFormData] = useState({title: "", description: "", location: "", location2: "", date: new Date()});
  const [file, setFile] =  useState(null);
  const [filename, setFilename] = useState(null);
  const [lists, setLists] = useState([]);
  const [titleError, setTitleError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [linkError, setLinkError] = useState(false);
  // bind state
  const { userList } = useSelector((state) => state.userList);
  const { showAddSession } = useSelector((state) => state.session);
  // Bind Actions
  const dispatch = useDispatch();
  const addSession = (form) => dispatch(addSessionAction(form));
  const editSession = (form, sessionId) => dispatch(editSessionAction(form, sessionId));
  const hideAddSession = () => dispatch(hideAddSessionAction());

  const resetData = () => {
    setFormData({title: "", description: "", location: "", location2: "", date: new Date(`${day} ${time}`)});
    setDay(new Date().toISOString().split('T')[0]);
    setTime(new Date().getHours() + ':' + (new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()));
    setFile(null);
    setFilename(null);
    setLists([]);
    hideAddSession()
  }

  useEffect(() => {
    const date = new Date(`${day} ${time}`);
    handleChange("date", date);
  }, [day, time]);

  useEffect(() => {
    const userListIds = lists.map((list)=>list.value);
    handleChange("userListIds", userListIds);
  }, [lists]);

  useEffect(() => {
    if (showAddSession?.userLists?.length && lists.length === 0) {
      setLists(showAddSession.userLists.map((list) => ({
        value: list.id,
        label: list.name,
      })));
    }
    if (showAddSession?.date) {
      setDay(new Date(showAddSession.date).toISOString().split('T')[0])
      setTime(new Date(showAddSession.date).getHours() + ':' + (new Date(showAddSession.date).getMinutes() < 10 ? '0' + new Date(showAddSession.date).getMinutes() : new Date(showAddSession.date).getMinutes()))
    }
    if (showAddSession?.id) {
      setFormData({...formData, title: showAddSession.title, description: showAddSession.description, filename: showAddSession.filename, date: showAddSession.date, location: showAddSession.location, location2: showAddSession.location2})
    }
    if (showAddSession?.filename) {
      setFilename(showAddSession.filename);
      checkFile(showAddSession.filename);
    }
  }, [showAddSession]);


  const handleChange = (key, data) => {
    setFormData({...formData, [key]:data})
  }

  const handleFileChange = (e) => {
    let theFile = null;
    if(e.target.files && e.target.files[0]) {
      theFile = e.target.files[0];
    }
    setFile(URL.createObjectURL(theFile));
    setFilename(theFile.name);
    handleChange("file", theFile);
  }

  const submit = () => {
    let hasError = false;
    // title
    if(formData.title?.length) {
      titleError && setTitleError(false);
    }
    else {
      setTitleError(true);
      hasError = true;
    }
    // description
    if(formData.description?.length <= 255) {
      descriptionError && setDescriptionError(false);
    }
    else {
      setDescriptionError(true);
      hasError = true;
    }
    // external link
    const reg = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    if(formData?.location2?.length == 0 || formData?.location2?.match(reg)){
      linkError && setLinkError(false);
    }
    else {
      setLinkError(true);
      hasError = true;
    }

    //
    if(!hasError) {
      if(showAddSession.id) {
        editSession(formData, showAddSession.id || null);
      }
      else {
        addSession(formData);
      }
      resetData();
    }
  }

  const displayFile = () => {
    if(filename) {
      let pdfSrc = file;
      if (!pdfSrc) {
        pdfSrc = getFileSource()
      }
      if(filename.toLowerCase().includes('.pdf')) {
        const iFrame = <iframe title="pdf-viewer" src={pdfSrc+"#toolbar=0"} width="100%" height="500px"  frameBorder="0"/>
        return iFrame;
      } else {
        return <Lazyload src={pdfSrc} alt="image"/>
      }
    }
    return null;
  }

  const getFileSource = () => {
    return `${A2VOTE_API}/file/o/${filename}`; 
  };

  const checkFile = async (filename = false) => {
    const status = await fetch(
      filename ? `${A2VOTE_API}/file/o/${filename}` : getFileSource()
    ).then((res) => res.status);
    if (status !== 200) {
      setFileError(true);
    }
  };

  const removeFile = () => {
    setFile(null);
    setFilename(null);
    setFileError(false);
    // Does session has file and we remove it ?
    // false => session has file but we remove it
    // null => session file stays empty
    if(showAddSession?.filename) {
      handleChange("file", false);
    }
    else {
      handleChange("file", null);
    }
  }

  return (
    <SidePageLayout
      title={ showAddSession?.id ? <FormattedMessage {...messages.edit_deliberation_session} /> : <FormattedMessage {...messages.new_deliberation_session} />}
      buttonMessage={showAddSession?.id ? <FormattedMessage {...messages.session_edit} /> : <FormattedMessage {...messages.session_add} />}
      onClose={resetData}
      onValid={submit}
    >
      <div>
        <FormattedMessage {...messages.session_name} />
      </div>
      <FormattedMessage {...messages.enter_session_name} >
        {(msg) => <Input inputError={titleError} placeholder={msg} type="text" onChange={(e)=>handleChange("title", e.target.value)} value={formData.title}/>}
      </FormattedMessage>
      {titleError && <MessageError> <FormattedMessage {...messages.session_title_error} /></MessageError>}
      <div><FormattedMessage {...messages.session_object} /></div>
      <FormattedMessage {...messages.enter_session_object} >
        {(msg) => <TextArea textAreaError={descriptionError} placeholder={msg} onChange={(e)=>handleChange("description", e.target.value)} value={formData.description} maxLength="255"/>}
      </FormattedMessage>
      {descriptionError && <MessageError> <FormattedMessage {...messages.session_description_error} /></MessageError>}
      <TimeAndDate>
        <DoubleInputContainer>
          <div><FormattedMessage {...messages.session_date} /></div>
          <Input type="date" onChange={(e)=>setDay(e.target.value)} value={day} className="dateAndTime" min={day}/>
        </DoubleInputContainer>
        <DoubleInputContainer>
          <div><FormattedMessage {...messages.session_hour} /></div>
          <Input type="time" onChange={(e)=>setTime(e.target.value)} value={time} className="dateAndTime" />
        </DoubleInputContainer>
      </TimeAndDate>
      <div><FormattedMessage {...messages.session_place} /></div>
      <FormattedMessage {...messages.enter_session_place} >
        {(msg) => <Input placeholder={msg} type="text" onChange={(e)=>handleChange("location", e.target.value)} value={formData.location} />}
      </FormattedMessage>
      <div><FormattedMessage {...messages.session_location2} /></div>
      <FormattedMessage {...messages.enter_session_lien} >
        {(msg) =><Input inputError={linkError} placeholder={msg} type="text" onChange={(e)=>handleChange("location2", e.target.value.trim() )} value={formData.location2}/>}
      </FormattedMessage>
      {linkError && <MessageError> <FormattedMessage {...messages.session_link_error} /></MessageError>}
      <div><FormattedMessage {...messages.user_group_summon} /></div>
      <FormattedMessage {...messages.user_group} >
        {(msg) =>
          <Select
            isMulti
            name="sessionUserLists"
            styles={customStyles}
            options={
              userList.map((list)=>{
                return { value: list.id, label: list.name };
              })
            }
            onChange={setLists}
            components={{ IndicatorSeparator:() => null }}
            placeholder={msg}
            value={lists}
          />
        }
      </FormattedMessage>
      <div><FormattedMessage {...messages.add_agenda} /></div>
      <FileContainer>
        <Input
          id="filesInput"
          type="file"
          onChange={handleFileChange}
          className="file"
          />
        <Label htmlFor="filesInput">
          <IconStyled icon="Upload" size="sm" className="label"/>
          <FormattedMessage {...messages.import_agenda} />
        </Label>
        {
          filename &&
            <IconStyled icon="Cross" size="sm" onClick={() => removeFile()} className="removeFile"/>
        }
      </FileContainer>
      <FileName>{filename ? filename : <FormattedMessage {...messages.no_file_upload} />}</FileName>
      {
        fileError ?
          <div>
            <FormattedMessage {...messages.file_error} />
          </div> :
          displayFile()
      }
    </SidePageLayout>
  )
}


export default AddSession;

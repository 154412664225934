import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// Tools
import { navigate } from "gatsby";
import styled from "styled-components";
import theme from "theme";
import Icon from "utils/icon";
// i18n
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import globalMessages from "containers/messages"
// Containers
import AddSession from "./AddSession";
import ListSession from './ListSession';
import ViewSession from './ViewSession';
import VoteResults from "./VoteResults";
import TopBarLayout from '../Layout/Topbar/topBar'
import ContentPaginate from "./ListSession/components/ContentPaginate";
// Actions
import { showAddSessionAction, getSessionsAction } from "actions/session";
import { getAllUserList as getAllUserListAction } from "actions/userList";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden; //prevent elevator
  ${props => props.upload && `pointer-events: none;` || null}
`;

const ChildContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const DisplayContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const SlideBarBackground = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
  background-color: ${props => props.showAddSession ? "rgba(9,67,103,.3)" : ""};
  z-index: ${props => props.showAddSession ? "2" : "-1"};
`;

const Return = styled.div`
  padding: 16px 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 80px;
  color: ${theme.darkgray};

  @media(max-width: 768px) {
    padding: 16px;
  }
`;

const AddSection = styled.div`
  display: flex;
  align-items: center;
`;

const Loading = styled.div`
margin-left: 10px;
width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,${theme.ui.blue} 95%,#0000) 50% 1px/8px 8px no-repeat,
    radial-gradient(farthest-side,#0000 calc(100% - 9px),#ccc 0);
  animation: s9 2s infinite linear;
}
@keyframes s9 {to{transform: rotate(1turn)}}
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-flow: row;
  background-color: ${theme.ui.blue};
  border-radius: 5px;
  padding: 8px;
  gap: 8px;
  align-items: center;
  color: ${theme.white};
  margin-left: 32px;
  width: fit-content;
  cursor: pointer;

  @media(max-width: 768px) {
    margin-left: 16px;
    border-bottom: 1px solid ${theme.ui.grey};
  }
`;

const Paginate = styled.div`
  background-color: ${theme.white};
  border-top: 1px solid ${theme.ui.grey};
  color: ${theme.darkgray};
  width: 100%;
  height: 30px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: 0;
`;

const Session = () => {
  // state
  //ordering
  const [orders, setOrders] = useState({
    limit: 5,
    offset: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  // bind state
  const { showAddSession, selectedSession, upload } = useSelector((state) => state.session);
  const { userList } = useSelector((state) => state.userList);
  const { vote }  = useSelector((state) => state.voteResults);
  const { totalSessions } = useSelector((state) => state.session);
  const intl = useIntl();
  // bind actions
  const dispatch = useDispatch();
  const addSession = () => dispatch(showAddSessionAction());
  const getAllUserList = useCallback(() => dispatch(getAllUserListAction()), [dispatch]);
  const getSessions = useCallback((filters, orders) => dispatch(getSessionsAction(filters, orders)), [dispatch]);
  const deliberation = useCallback(() => dispatch({type: 'DELIBERATION'}), [dispatch]);
  // filtering
  const [filters, setFilters] = useState({
    title: '',
    description: '',
    startDate: false,
    endDate: false,
    location: '',
  });
  
  const handleChangePage = () => {
    let skip = orders.limit * (currentPage - 1);
    let updatedValue = {};
    updatedValue = {offset: skip}
    setOrders(orders => ({
      ...orders,
      ...updatedValue
    }));
  }

  useEffect(()=>{
    if(userList && !userList.length) {
      getAllUserList();
    }
    deliberation();
  }, [getAllUserList, deliberation, userList])

  useEffect(() => {
    getSessions()
  }, [getSessions, orders])

  useEffect(() => {
    if (upload) {
      dispatch({
        type: "CREATE_AND_UPDATE_ALERT",
        payload: {
          content: (
            <AddSection>
              {upload.toString().substring(0, 3)}
              {`% - `}
              {intl.formatMessage(messages.session_upload_loading)}
            </AddSection>
          ),
          options: {
            position: "top-center",
            id: "upload",
            icon: <Loading />,
          },
          type: "loading",
        },
      });
    } else {
      dispatch({
        type: "REMOVE_ALERT",
        payload: {
          id: "upload",
        },
      });
    }
  }, [upload]);

  return (
    <Container upload={upload}>
      <TopBarLayout />
      {
        selectedSession ?
          vote ?
            <VoteResults />
          :
            <ViewSession />
        :
          <React.Fragment>
            <ChildContainer showAddSession={showAddSession}>
              <Return onClick={() => navigate("/admin")}>
                <Icon icon="LeftArrow" size="sm"/>
                <FormattedMessage {...globalMessages.return} />
              </Return>
              <DisplayContainer>
                  <ButtonDiv onClick={addSession}>
                    <Icon icon="Plus" size="sm"/>
                    <FormattedMessage {...messages.create_session} />
                  </ButtonDiv>
                <ListSession getSessions={getSessions} filters={filters} setFilters={setFilters}/>
                <Paginate>
                  <ContentPaginate currentPage={currentPage} limit={orders.limit} totalSessions={totalSessions} onChange={handleChangePage} setCurrentPage={setCurrentPage}/>
                </Paginate>
              </DisplayContainer>
            </ChildContainer>
            <SlideBarBackground showAddSession={showAddSession}/>
            {showAddSession && <AddSession />}
          </React.Fragment>
      }
    </Container>
  );
}

export default Session;